var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import KeyCode from 'rc-util/lib/KeyCode';
import Tooltip from '../tooltip';
import Button from '../button';
import { convertLegacyProps } from '../button/button';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale/default';
import { ConfigContext } from '../config-provider';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import { cloneElement } from '../_util/reactNode';
const Popconfirm = React.forwardRef((props, ref) => {
    const [visible, setVisible] = React.useState(props.visible);
    React.useEffect(() => {
        if ('visible' in props) {
            setVisible(props.visible);
        }
    }, [props.visible]);
    React.useEffect(() => {
        if ('defaultVisible' in props) {
            setVisible(props.defaultVisible);
        }
    }, [props.defaultVisible]);
    const settingVisible = (value, e) => {
        if (!('visible' in props)) {
            setVisible(value);
        }
        if (props.onVisibleChange) {
            props.onVisibleChange(value, e);
        }
    };
    const onConfirm = (e) => {
        settingVisible(false, e);
        if (props.onConfirm) {
            props.onConfirm.call(this, e);
        }
    };
    const onCancel = (e) => {
        settingVisible(false, e);
        if (props.onCancel) {
            props.onCancel.call(this, e);
        }
    };
    const onKeyDown = (e) => {
        if (e.keyCode === KeyCode.ESC && visible) {
            settingVisible(false, e);
        }
    };
    const onVisibleChange = (value) => {
        const { disabled } = props;
        if (disabled) {
            return;
        }
        settingVisible(value);
    };
    const renderOverlay = (prefixCls, popconfirmLocale) => {
        const { okButtonProps, cancelButtonProps, title, cancelText, okText, okType, icon } = props;
        return (<div className={`${prefixCls}-inner-content`}>
        <div className={`${prefixCls}-message`}>
          {icon}
          <div className={`${prefixCls}-message-title`}>{getRenderPropValue(title)}</div>
        </div>
        <div className={`${prefixCls}-buttons`}>
          <Button onClick={onCancel} size="small" {...cancelButtonProps}>
            {cancelText || popconfirmLocale.cancelText}
          </Button>
          <Button onClick={onConfirm} {...convertLegacyProps(okType)} size="small" {...okButtonProps}>
            {okText || popconfirmLocale.okText}
          </Button>
        </div>
      </div>);
    };
    const { getPrefixCls } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, placement, children, overlayClassName } = props, restProps = __rest(props, ["prefixCls", "placement", "children", "overlayClassName"]);
    const prefixCls = getPrefixCls('popover', customizePrefixCls);
    const prefixClsConfirm = getPrefixCls('popconfirm', customizePrefixCls);
    const overlayClassNames = classNames(prefixClsConfirm, overlayClassName);
    const overlay = (<LocaleReceiver componentName="Popconfirm" defaultLocale={defaultLocale.Popconfirm}>
      {(popconfirmLocale) => renderOverlay(prefixCls, popconfirmLocale)}
    </LocaleReceiver>);
    return (<Tooltip {...restProps} prefixCls={prefixCls} placement={placement} onVisibleChange={onVisibleChange} visible={visible} overlay={overlay} overlayClassName={overlayClassNames} ref={ref}>
      {cloneElement(children, {
        onKeyDown: (e) => {
            var _a, _b;
            if (React.isValidElement(children)) {
                (_b = children === null || children === void 0 ? void 0 : (_a = children.props).onKeyDown) === null || _b === void 0 ? void 0 : _b.call(_a, e);
            }
            onKeyDown(e);
        },
    })}
    </Tooltip>);
});
Popconfirm.defaultProps = {
    transitionName: 'zoom-big',
    placement: 'top',
    trigger: 'click',
    okType: 'primary',
    icon: <ExclamationCircleFilled />,
    disabled: false,
};
export default Popconfirm;
