var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcTextArea from 'rc-textarea';
import omit from 'rc-util/lib/omit';
import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import ClearableLabeledInput from './ClearableLabeledInput';
import { ConfigContext } from '../config-provider';
import { fixControlledValue, resolveOnChange, triggerFocus } from './Input';
import SizeContext from '../config-provider/SizeContext';
const TextArea = React.forwardRef((_a, ref) => {
    var { prefixCls: customizePrefixCls, bordered = true, showCount = false, maxLength, className, style, size: customizeSize } = _a, props = __rest(_a, ["prefixCls", "bordered", "showCount", "maxLength", "className", "style", "size"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const innerRef = React.useRef(null);
    const clearableInputRef = React.useRef(null);
    const [value, setValue] = useMergedState(props.defaultValue, {
        value: props.value,
    });
    const prevValue = React.useRef(props.value);
    React.useEffect(() => {
        if (props.value !== undefined || prevValue.current !== props.value) {
            setValue(props.value);
            prevValue.current = props.value;
        }
    }, [props.value, prevValue.current]);
    const handleSetValue = (val, callback) => {
        if (props.value === undefined) {
            setValue(val);
            callback === null || callback === void 0 ? void 0 : callback();
        }
    };
    const handleChange = (e) => {
        handleSetValue(e.target.value);
        resolveOnChange(innerRef.current, e, props.onChange);
    };
    const handleReset = (e) => {
        handleSetValue('', () => {
            var _a;
            (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        });
        resolveOnChange(innerRef.current, e, props.onChange);
    };
    const prefixCls = getPrefixCls('input', customizePrefixCls);
    React.useImperativeHandle(ref, () => {
        var _a;
        return ({
            resizableTextArea: (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea,
            focus: (option) => {
                var _a, _b;
                triggerFocus((_b = (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea) === null || _b === void 0 ? void 0 : _b.textArea, option);
            },
            blur: () => { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
        });
    });
    const textArea = (<RcTextArea {...omit(props, ['allowClear'])} maxLength={maxLength} className={classNames({
        [`${prefixCls}-borderless`]: !bordered,
        [className]: className && !showCount,
        [`${prefixCls}-sm`]: size === 'small' || customizeSize === 'small',
        [`${prefixCls}-lg`]: size === 'large' || customizeSize === 'large',
    })} style={showCount ? undefined : style} prefixCls={prefixCls} onChange={handleChange} ref={innerRef}/>);
    let val = fixControlledValue(value);
    // Max length value
    const hasMaxLength = Number(maxLength) > 0;
    // fix #27612 将value转为数组进行截取，解决 '😂'.length === 2 等emoji表情导致的截取乱码的问题
    val = hasMaxLength ? [...val].slice(0, maxLength).join('') : val;
    // TextArea
    const textareaNode = (<ClearableLabeledInput {...props} prefixCls={prefixCls} direction={direction} inputType="text" value={val} element={textArea} handleReset={handleReset} ref={clearableInputRef} bordered={bordered}/>);
    // Only show text area wrapper when needed
    if (showCount) {
        const valueLength = Math.min(val.length, maxLength !== null && maxLength !== void 0 ? maxLength : Infinity);
        let dataCount = '';
        if (typeof showCount === 'object') {
            dataCount = showCount.formatter({ count: valueLength, maxLength });
        }
        else {
            dataCount = `${valueLength}${hasMaxLength ? ` / ${maxLength}` : ''}`;
        }
        return (<div className={classNames(`${prefixCls}-textarea`, {
            [`${prefixCls}-textarea-rtl`]: direction === 'rtl',
        }, `${prefixCls}-textarea-show-count`, className)} style={style} data-count={dataCount}>
          {textareaNode}
        </div>);
    }
    return textareaNode;
});
export default TextArea;
