var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigConsumer } from '../config-provider';
const Divider = props => (<ConfigConsumer>
    {({ getPrefixCls, direction }) => {
    const { prefixCls: customizePrefixCls, type = 'horizontal', orientation = 'center', className, children, dashed, plain } = props, restProps = __rest(props, ["prefixCls", "type", "orientation", "className", "children", "dashed", "plain"]);
    const prefixCls = getPrefixCls('divider', customizePrefixCls);
    const orientationPrefix = orientation.length > 0 ? `-${orientation}` : orientation;
    const hasChildren = !!children;
    const classString = classNames(prefixCls, `${prefixCls}-${type}`, {
        [`${prefixCls}-with-text`]: hasChildren,
        [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
        [`${prefixCls}-dashed`]: !!dashed,
        [`${prefixCls}-plain`]: !!plain,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<div className={classString} {...restProps} role="separator">
          {children && <span className={`${prefixCls}-inner-text`}>{children}</span>}
        </div>);
}}
  </ConfigConsumer>);
export default Divider;
